import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Module } from '../models/module.model';
import { ProgressBarService } from '../services/progress-bar.service';
import { QuizStoreService } from '../services/quiz-store.service';
import { QuizService } from '../services/quiz.service';
import { SetupService } from '../services/setup.service';
import { JobApplication } from '../models/job-application.model';
import { Application } from '../classes/application.class';
import { Job } from '../models/job.model';
import { Subject} from 'rxjs';
import { QUIZ_TYPES } from '../resources/quiz-types';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { VideoQuestionsModule } from '../modules/video-questions/video-questions.module';

@Component({
  selector: 'app-application-complete',
  templateUrl: './application-complete.component.html',
  styleUrls: ['./application-complete.component.scss'],
  imports: [NgClass, TranslateModule, VideoQuestionsModule, NgFor, NgIf],
  standalone: true
})
export class ApplicationCompleteComponent implements OnInit, OnDestroy {

  @HostBinding('class.route-card') card = true;
  @HostBinding('class.last-card') lastCard = true;
  @HostBinding('class.application-complete') applicationComplete = true;

  application: JobApplication;
  businessQuestions = false;
  jobInfo: Job;

  modules: Module[] = [
    {
      name: QUIZ_TYPES.BUSINESS_QUESTIONS,
      translationKey: 'APPLICATION_COMPLETE.SKILLS_TITLE',
    },
    {
      name: QUIZ_TYPES.VIDEO_QUESTIONS,
      translationKey: 'APPLICATION_COMPLETE.VIDEO_QUESTION_LABEL',
    },
  ];

  activeModules: Module[] = [];

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private progressBarService: ProgressBarService,
    private quizStore: QuizStoreService,
    private quizService: QuizService,
    private setupService: SetupService
  ) {}

  ngOnInit(): void {
    this.progressBarService.updateProgress(100);
    this.setupService
      .getApplicationInfo()
      .subscribe((application: Application) => {
        this.setApplication(application)
      });
  }

  setApplication({jobInfo, jobApplication}: Application): void {
    this.application = jobApplication;
    this.jobInfo = jobInfo;

    const { orderOfQuestionsModules } = jobApplication;

    this.setActiveModules(orderOfQuestionsModules);
    this.businessQuestions = orderOfQuestionsModules.indexOf(QUIZ_TYPES.BUSINESS_QUESTIONS) >= 0;
  }

  setActiveModules(orderOfQuestionsModules: string[]): void {
    orderOfQuestionsModules
      .forEach((moduleName: string) => {
        if (moduleName === QUIZ_TYPES.KNOCKOUT_QUESTIONS || moduleName === QUIZ_TYPES.INHOUSE_QUESTION) {
          return;
        }
        const moduleIndex = this.modules
          .findIndex((module: Module) => module.name === moduleName);

        if (moduleIndex >= 0) {
          this.activeModules.push(this.modules[moduleIndex]);
        }
      });
  }

  ngOnDestroy(): void {
    this.quizStore.reset();
    this.quizService.setQuestionAnswered({ answer: null }, null);
    sessionStorage.removeItem('jobId');
    sessionStorage.removeItem('companyId');
    sessionStorage.removeItem('companyDomain');
    sessionStorage.removeItem('jobCompanyLogo');
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}

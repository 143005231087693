<div class="container">
  <div class="modules"
       [ngClass]="{'onlyModules': !businessQuestions}">
       <div>
        <h3 class="title">{{ jobInfo?.thankYouMessage }} {{application?.candidate.name}}!</h3>
        <p [innerHTML]="jobInfo?.thankYouMainMessage"></p>
       </div>
    <ul class="module-list">
      <li *ngFor="let module of activeModules"
          class="module-item">
        <div class="module">
          <i class="icon-checked"></i>
          <div class="name">{{ module.translationKey | translate }}</div>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="businessQuestions" class="skills twoModulesWidth">
    <h3>{{ 'APPLICATION_COMPLETE.SKILLS_TITLE' | translate }}</h3>
    <div class="results">
      <div class="results-dot"></div>
      <p>{{ 'APPLICATION_COMPLETE.SKILLS_RESULT' | translate }}</p>
      <p class="results-number">{{ application?.businessQuestionsScore }}%</p>
    </div>
    <div class="horizontal-graph">
      <div class="bar"
           [style.width.%]="application?.businessQuestionsScore"></div>
    </div>
  </div>
</div>
